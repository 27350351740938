$(document).ready(function () {
	// MENU MOBILE
	$('.menu-nav').click(function(e){
	    e.preventDefault();
	    $(this).toggleClass('opened');
	    $('nav').toggleClass('opened');
	    $('body').toggleClass('overflow');
  	});
	$('.menu li a').click(function(e){
	    e.preventDefault();
	    $('.menu-nav').removeClass('opened');
		$('nav').removeClass('opened');
		$('body').removeClass('overflow');
  	});

	// SMOOTH SCROLL
	$('.menu li a, .menu-footer ul li a').on('click', function(event) {
		event.preventDefault();
		var target = $(this).attr('href');
		var livroPage = '/mariana-fonseca/o-futuro-nasceu-antes/';
		if (window.location.pathname !== livroPage) {
			window.location.href = livroPage + target;
		} else {
			var offset = $(target).offset().top;
			$('html, body').animate({
				scrollTop: offset
			}, 1000);
		}
	});
	if (window.location.pathname === '/mariana-fonseca/o-futuro-nasceu-antes/' && window.location.hash) {
        var target = window.location.hash;
        var offset = $(target).offset().top;
        $('html, body').animate({
            scrollTop: offset
        }, 1000);
    }

	// TABS
	$('.tab-news > div').click(function() {
        if (!$(this).hasClass('active')) {
            $('.tab-news > div.active').removeClass('active');
            $(this).addClass('active');
            $('.tab-content-news > div.active').removeClass('active');
            $('.tab-content-news > div').eq($(this).index()).addClass('active');
        }
    });

	// MODAL NEWS
    $('.grid-item-news').on('click', function() {
        $('.overlay, .modal-news').addClass('opened');
        $('body').addClass('overflow');
    });
    $('.close-modal').on('click', function() {
        $('.overlay, .modal-news').removeClass('opened');
        $('body').removeClass('overflow');
    });
	function openModal(postId) {
		$.ajax({
			type: 'POST',
			url: ajaxUrl,
			data: {
				action: 'get_post_data',
				post_id: postId
			},
			success: function(data) {
				var modal = $('#modal-news');
				var thumbVideo = modal.find('.thumb-video');
				var h2 = modal.find('h2');
				var div = modal.find('.content');
				thumbVideo.empty();
				if (data.video_id) {
					thumbVideo.append('<iframe width="560" height="315" src="https://www.youtube.com/embed/' + data.video_id + '" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
				} else if (data.has_thumbnail) {
					var img = $('<img />', { src: data.thumbnail, alt: data.title });
					thumbVideo.append(img);
				}
				h2.text(data.title);
				var cleanContent = data.content.replace(/<!--[\s\S]*?-->/g, '');
				div.html(cleanContent);
				$('.overlay, .modal-news').addClass('opened');
				$('body').addClass('overflow');
			}
		});
	}
	
    // Função para filtrar posts
    function filterPosts(searchTerm) {
        $('.grid-item-news').each(function() {
            var postTitle = $(this).find('h3').text().toLowerCase();
            if (postTitle.includes(searchTerm.toLowerCase())) {
                $(this).show();
            } else {
                $(this).hide();
            }
        });
    }

    // Evento de input para o campo de busca
    $('.search-news input[type="search"]').on('input', function() {
        var searchTerm = $(this).val();
        filterPosts(searchTerm);
    });
	
	// Reatribuir eventos após carregar novos posts
	function reassignModalEvents() {
        $('.grid-item-news').off('click').on('click', function() {
            var postId = $(this).data('post-id');
            var embedUrl = $(this).data('embed-url');
            var thumbnailUrl = $(this).data('thumbnail-url');
            openModal(postId, embedUrl, thumbnailUrl);
        });
    }

	// LOAD MORE NEWS
    $('.load-more-news').on('click', function() {
        var button = $(this);
        var paged = button.data('paged') || 2;
        var category = button.data('category');
        $.ajax({
            url: ajaxUrl,
            type: 'POST',
            data: {
                action: 'load_more_news',
                paged: paged,
                category: category
            },
            success: function(response) {
                var data = JSON.parse(response);
                if (data.html) {
                    $('.grid-news[data-category="' + category + '"]').append(data.html);
                    button.data('paged', paged + 1);
                    reassignModalEvents();
                    filterPosts($('.search-news input[type="search"]').val());
                }
                if (data.no_more_posts) {
                    button.hide();
                }
            },
            error: function() {
                alert('Ocorreu um erro. Por favor, tente novamente.');
            }
        });
    });

    reassignModalEvents();
	
});